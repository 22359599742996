// 
// faq.scss
//

.faq-question-q-box {
    height: 30px;
    width: 30px;
    color: $danger;
    text-align: center;
    border-radius: 50%;
    float: left;
    font-weight: $font-weight-bold;
    line-height: 30px;
    background-color: rgba($danger,0.15);
}

.faq-question {
    margin-top: 0;
    margin-left: 50px;
    font-size: 16px;
}

.faq-answer {
    margin-left: 50px;
    color: $gray-600;
}