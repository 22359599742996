// 
// lightbox.scss
//


.white-popup-block {
    background: $white;
    padding: 20px 30px;
    text-align: left;
    max-width: 650px;
    margin: 40px auto;
    position: relative;
}