// 
// pricing.scss
//

.card-pricing {
    position: relative;
    border: 2px solid transparent;
    border-radius: 10px;
    
    .card-pricing-plan-name {
        padding-bottom: 20px;
    }

    .card-pricing-icon {
        font-size: 22px;
        background-color: rgba($danger,0.1);
        height: 60px;
        display: inline-block;
        width: 60px;
        line-height: 62px;
        border-radius: 50%;
    }

    .card-pricing-price {
        padding: 30px 0 0;

        span {
            font-size: 40%;
            color: $gray-600;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
    .card-pricing-features {
        color: $gray-600;
        list-style: none;
        margin: 0;
        padding: 20px 0 0 0;

        li {
            padding: 10px;
        }
    }
}

.card-pricing-recommended {
    background-color: rgba($info,0.1);
    color: $info;
    border-color: $info;

    .card-pricing-icon {
        background-color: rgba($info,0.2);
    }
    .card-pricing-features,.card-pricing-price span {
        color: $info;
    }
}